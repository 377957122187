


















import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "Home",
  data() {
    return {
      documents: [],
    };
  },
  methods: {
    downloadLink(filename: string): string {
      return `${process.env.VUE_APP_API_BASE_URL}/files/${filename}`;
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/documents`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        this.documents = response.data.data;
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          this.$router.push("/");
        }
      });
  },
});
